<template>
<div>
    <Header />

        <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Beneficiaries</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-5 bg-gradient arrow-bottom">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <p>Every woman who is confined to one of Mexico's prisons <b>has a story that is unique to her</b>. Perhaps they were in the wrong place at the wrong time, or a personal circumstance forced them into making a wrong decision.</p>
                        <p>Studies show that the majority of women in Mexico's prisons come from a <b>disadvantaged social or economic background</b>, or both. Many--if not all--of the women have lived in poverty and have suffered physical or mental abuse, along with violence. These women didn't imagine the consequences, and therefore, they ended up behind bars, alone and often separated from their children, and isolated. The programs through The Rebeca Lan Foundation allow these women to integrate into society after prison life, and live the rest of their lives with dignity. Our programs are designed to <b>empower women</b>, allowing them to become functional members of society, providing them a chance to make a difference for themselves and their families.</p>
                    </div>
                </div>
            </div>
        </div>

        <div id="testimonios" class="pt-5 bg-gradient">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="pb-3 mb-4"><b>Testimonies of female prisoners</b></h2>
                    </div>
                </div>
                <div class="row row-cols-2 row-cols-md-4">

                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioNueve">
                            <img :src="`${publicPath}storage/testimonios/9.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioDiez">
                            <img :src="`${publicPath}storage/testimonios/10.jpg`" alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioDos">
                            <img :src="`${publicPath}storage/testimonios/4.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioTres">
                            <img :src="`${publicPath}storage/testimonios/5.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioUno">
                            <img :src="`${publicPath}storage/testimonios/2.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioCuatro">
                            <img :src="`${publicPath}storage/testimonios/6.jpg`" alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioCinco">
                            <img :src="`${publicPath}storage/testimonios/8.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioSeis">
                            <img :src="`${publicPath}storage/testimonios/1.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>

                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioSiete">
                            <img :src="`${publicPath}storage/testimonios/3.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="col mb-5">
                        <a href="" class="card shadow border-0" data-toggle="modal" data-target="#testimonioOcho">
                            <img :src="`${publicPath}storage/testimonios/7.jpg`"  alt="" class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioUno" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/2.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Yo una interna más de la Penitenciaria La Mesa B.C</p>
                        <p>Hoy me doy cuenta que tengo un rostro ante la sociedad, y que no por haberme equivocado voy a ser invisible. Como en estoy 4 años, me he dado cuenta que valgo mucho y que soy un ser humano maravilloso, si tal vez con un oscuro pasado, por la vida no tan buena que viví.</p>
                        <p>Aquí me di cuenta de lo hermosa y maravillosa que soy y aunque estoy privada de mi libertad SOY LIBRE  allá afuera era presa de mi propia vida y tal vez infeliz, nunca valore lo hermosa que era mi familia y lo importante que son para mi vida. Quiero agregar que en todo este tiempo he podido pensar y meditar sobre pase he pasado y sigo pasado y recuperé la más importante de mi vida mi FAMILIA quienes nunca tome en cuenta; de todo esto aprendí que ellos son el pilar de mis sentimientos par que yo siga de pie, feliz y sonriedo. Por ahora sigo luchando por salir adelante día a día me levanto con positivismo y amor hacia mi misma, también aprendí que si estoy bien también ellos lo estarán. Solo quiero agregar que nunca se dejen llevar por lo que la gente cuenta, porque solo los que estamos aquí sabemos la realidad de lo que pasa y nos pasó no juzguen por lo que la gente aparenta... Gracias...</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioDos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/4.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Comenzare por decirte que lo que tu y todos los que han apoyado son de gran bendición para todas; yo veo esto como un acto de maor y caridad, pero me pregunto en realidad porque lo haces, y lo mas sensacional es que: tu no te fijas en lo que hicimos, sino en como nos sentimos deseo que dios te recompense.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioTres" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/5.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Este dia de hoy fue muy especial por un momento sentí que estaba libre.</p>
                        <p>Gracias a las fundacion Rebeca Lan. Y que dios los siga llenando de vendiciones y les siga dando, para que siga cupliendo sueños.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioCuatro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/6.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>A nombre de todo el equipo de internas Q colaboramos para la realiacion les damos las gracias por permitirnos ser parte de tan hermosa experiencia y darnos la libertad de divertirnos y sacar una sonrisa de cada una de nosotras. La parte mas emotiva de la realización Fue al quebrar huevos pues hace mucho La mayoría no lo Aciamos y en base A nuestras anecdotas de niñas es lo que mas comíamos por nuestras mamas en si nos regresaron a nuestra infancia le agradecemos Alas Autoridades y ala Fundacion Rebeca lan por la confianza brindada y al chef. Rufo por tomarse el tiempo con nosotras de compartir sus secreto de cocina. Bendiciones Para toda y todos. Esperamos y les guste, buen probecho.</p>
                        <p><b>Y Gracias x la experiencia ai</b></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioCinco" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/8.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Yo quiero agradecer con todo mi corazón y mis mejores bendiciones para la fundación Rebeca Lan. Tener a mujeres tan luchonas y se pogan en nuestro lugar, a mi en lo personal me motivan para que cuando salga, no me olvide de tanta mujer que al igual que yo sufre de ausencia de sus seres queridos Agradezco todos los lindo detalles que han tenido hacia nosotras y me han ayudado a recuperar mi autoestima MUCHAS GRACIAS por todos los momento hermosos que han traido a este lugar. Rachel en lo particular eres para mi una motivación para aprender que el que percevera alcanza yo tengo 26 años y me motiva tú amor hacia nosotras y tú apoyo incondicional. Le doy gracias a dios por que dicha fundación este aportando tantas cosas hermosas en mi vida. Gracias por mi autoestima, seguridad que he recuperado y todos esos momentos que han pintado en mi cara motivación y sonrisas para ser feliz, Rebeca Lan muchas gracias por cada integrante de está fundación son FANTASTICAS y les deceo lo mejor del mundo y sean siempre bendecidad para que puedan pasar fronteras y llegar hasta la última mujer que pase dicha situación. Gracias por todo y gracias por ayudarme conocer lo desconocido.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioSeis" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/Alejandra_Mireya.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Doy gracias a la fundación Rebeca Lan</p>
                        <p>Por todo lo que nos a Logrado traer a este lugar, tanto como los eventos y la biblioteca, gracias por Regalarnos esos momento que para nosotras son muy valiosos el darnos esa dicha de disfrutar unos minutos muy diferentes a lo Rutinario, y mas gracias aun por despertarme el interés de la lectura porque es algo que me gusto para inculcárselo a mis hijos.</p>
                        <p>Gracias Rachel y a todo tu equipo y a todas las personas que nos regalaron unos minutos de su Vida me acen sentir no rechazada por la sociedad.</p>
                        <p><b>“Pensamiento”<br>
                        Cambiaria los ruidos de yaves y candados por las sonrrisas de mis hijos, Gracias Dios por darme la dicha de ser madre.</b></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioSiete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/Lorena_martinez.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Soy Lorena Martines una de las muchachas que te ayudo con las roscas. Me atrevi a escribirte porque ya no se que hacer con la situación de mi ija que esta en el Dif. Tengo 5 añs que no se nada de ella. Desconosco su paradero y pues esto inquieta. Dios me a dado la fortaleza y fuerza para salir adelante. Eh pedido ayuda y solo me hacen ilusionarme que me van a ayudar, y no me ayudan, es por eso que acurri a ti para ver si tu me puedes ayudar con esta situación Tu tienes tus contactos por fuera.Por favor Rachel se que eh cometido muchos errores en la vida y eh tomado malas decisiones también pero eso no quita el derecho de no saber de mi hija, el temor mio es que la den en adopción y nunca más la vuelva a ver eso me daría mucha tristeza. El espiitu santo me puso en mi mente que te escribiera esta carta, tengo fe y la esperanza en dios te va a guiar para ayudarme, Gracias por escucharme que dios te bendiga. Y gracias por hacerme la mujer contenta en este lugar y que a pesara de mis tribulaciones siempre tngo una sorrisa en mi rosto , y sigo para adelante pensndo en que tengo otro hijo por el cual tengo que hecharle ganas a seguir adelante con la frente en alto.</p>
                        <p>Gracias</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioOcho" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/Ma_Esther_C.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Hola</p>
                        <p>Quiero que me conoscas como soy igual que tú solo que yo no puedo ir y venir como tu, por que estoy aquí en este lugar en donde todo es diferente en donde tu estas pero también sufro, como tu por no tener a mi familia a mi lado y quisiera estar con ellos y tu donde estas con tu familia o lejos de ella extrañándolos la diferencia es que yo no puedo correr con ellos por estar aquí en este lugar y tu si puedes si quieres puedes correr y estar con ellos abrasarlos pero muchas veces no lo haces sabes solo quiero decirte que no dejes a tu familia porque yo aprendi que nadie esta a expensas de pisar un lugar como este y cuando estamos en este lugar aprendemos a balorar a nuestra familia, nuestros hijos y padres, pero tu que puedes recosiliate y diles lo mucho que los amas y abrasalos y perdonalos y resibe su perdon haslo tu que puedes.</p>
                        <p>Hoy yo te deseo que un dia me conoscas y dejes de tener o pensar que las que estamos aquí somos diferentes “Solo somos mujeres”.</p>
                        <p><b>Dios te bendiga.</b></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioNueve" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/Sandra_Luz.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Quiero agradecer primeramente a dios por mantenerme bien de salud y a la fundación Rebeca Lane, en especial a Rachel por darme la oportunidad de sentirme útil y que puedo hacer algo por los demás, me sentí realmente feliz al darme cuenta de que soy capaz de hacer algo diferente de lo que he hecho ya que nunca había hecho una rosca  y salir de la rutina que llevo aquí fue algo especial, me sentí bien con mis compañeras que me apoyaron en todo y por ultimo pero o menos importante quiero agradecer a las autoridades por permitir que esto sea posible y por considerarme para ser parte de esta gran experiencia. Gracias!</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testimonioDiez" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Testimonio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="`${publicPath}storage/testimonios/zilpa_v.jpg`" alt="" class="img-fluid">
                    <div class="modal-body">
                        <p>Quiero agradecer a la fundación Rebeca lan por el apoyo tan lindo que me ha brindado, cuando me sentía rechazada por la sociedad cuando me sentia sola, apareció una gran mujer Rachel que con sus palabras de aliento y sus libros me enseño que soy como cualquier persona que no porque este en este lugar dejo de ser importante, me ayudo a recuperar mi autoestima y valor como mujer hoy se que soy muy valiosa y que no soy invisible gracias fundacion Rebeca lan gracias Rachel por tus libros que me ayudan a seguir soñando.</p>
                    </div>
                </div>
            </div>
        </div>


    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/beneficiarios.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/en/Templates/Header'
import Footer from '@/modules/en/Templates/Footer'

export default {
    name: "Beneficiarios",
    components:{
        Header,
        Footer
    },
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

